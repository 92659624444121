import axios from 'axios'
import { to } from 'await-to-js'
import { API_URL } from '../configs/constants'

const http = {
  resources: axios.create({
    baseURL: API_URL,
    withCredentials: true
  }),
  async get(url, options = {}, isPromise = false) {
    const request = this.resources.get(url, options)
    return isPromise ? request : to(request)
  },
  async post(url, data, options = {}) {
    return to(this.resources.post(url, data, options))
  },
  async put(url, data, options = {}) {
    return to(this.resources.put(url, data, options))
  },
  async delete(url, options = {}) {
    return to(this.resources.delete(url, options))
  }
}

http.resources.interceptors.request.use(config => {
  const csrfToken = localStorage.getItem('csrf_token')
  if (csrfToken) {
    config.headers.common['csrf-token'] = csrfToken
  }
  return config
})

http.resources.interceptors.response.use(
  response => {
    if (response.data.csrf_token) {
      localStorage.setItem('csrf_token', response.data.csrf_token)
    }
    return response
  },
  ret => {
    const error = ret.response.data.error
    if (typeof error === 'undefined') {
      return Promise.reject(ret)
    }
    if (error.status) {
      if (error.status === 'user-logged-out') {
        localStorage.clear()
        window.location.href = '/'
      }
    }
    return Promise.reject(ret)
  }
)

export default http
