<template>
  <div id="app">
    <nav>
      <img src="@/assets/images/logo-ia.jpg" alt="Logo Institut Agro Enseignement à distance" />
      <h1 class="ml-3">Services apprenants</h1>
      <hollow-dots-spinner
        v-if="userLoading"
        class="ml-auto"
        :animation-duration="800"
        :dot-size="15"
        :dots-num="3"
        color="rgb(37, 44, 63)"
      />
      <div class="user ml-auto" v-else-if="user">
        <span>{{ user.first_name }}&nbsp;{{ user.last_name }}</span>
        <a class="ml-2" href="#deconnexion" title="Se déconnecter" @click="logOut">
          <fa-icon icon="sign-out-alt"></fa-icon>
        </a>
      </div>
      <a v-else :href="loginUrl" class="ml-auto">
        Accès enseignant&nbsp;
        <span class="emoji">📚</span>
      </a>
    </nav>
    <div class="apps-list">
      <app-card title="Combien de temps travailler ?" :image="require('@/assets/images/hourglass.jpeg')">
        <span>Calculer le nombre d'heures de travail estimées pour réussir votre formation</span>
        <template class="actions" v-slot:footer>
          <btn type="accent" link="/calculateur-temps" :icon="['far', 'clock']">Commencer</btn>
          <btn v-if="user" type="secondary" class="ml-1" flat link="/calculateur-temps/admin" icon="cogs"
            >Administrer</btn
          >
        </template>
      </app-card>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/common/action/Btn'
import AppCard from '@/components/AppCard'
import { HollowDotsSpinner } from 'epic-spinners'
import { API_URL, CAS_URL, CLIENT_URL } from '@/configs/constants'
import { faClock, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { faSignOutAlt, faCogs, faUpload } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faClock, faSignOutAlt, faCogs, faCalendarAlt, faUpload)

export default {
  name: 'app',
  data() {
    return {
      state: this.$store.state,
      loginUrl: `${CAS_URL}login?service=${API_URL}auth?client=${CLIENT_URL}`,
      userLoading: true
    }
  },
  computed: {
    user() {
      return this.state.user
    }
  },
  methods: {
    async logOut() {
      await this.$http.get('logout')
      this.$store.setUser(null)
    }
  },
  async created() {
    const [err, ret] = await this.$http.get('user')
    if (!err) {
      this.$store.setUser(ret.data.user)
    }
    this.userLoading = false
  },
  components: {
    Btn,
    AppCard,
    HollowDotsSpinner
  }
}
</script>

<style lang="scss">
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-width: 100%;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $grey-8;
  background: $grey-1;

  nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    height: 60px;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    background: $secondary;
    color: #fff;
    border-bottom: 2px solid $grey-7;
    box-shadow: $main-box-shadow;

    img {
      max-height: 90%;
      width: auto;
    }

    h1 {
      font-weight: 500;
    }

    a {
      text-transform: uppercase;
      font-weight: 500;
      color: $grey-3;

      &:hover {
        color: $grey-2;
      }
    }

    .user {
      display: flex;
      align-items: center;
      font-weight: 500;

      a {
        font-size: 1.5rem;
      }
    }
  }

  .apps-list {
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 90%;

    .card {
      margin-bottom: 1rem;
    }

    @media (min-width: 640px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1.5rem;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1400px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  a {
    color: $accent;
    text-decoration: none;

    &:hover {
      color: $accent-4;
    }

    .emoji {
      font-size: 1.2rem;
    }
  }
}
</style>
