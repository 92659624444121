<template>
  <component
    :is="tag"
    :type="buttonType"
    :href="link"
    :target="newWindows ? '_blank' : null"
    :class="classes"
    @click="onClick"
  >
    <span>
      <slot></slot>
    </span>
    <div v-if="icon" class="icon">
      <fa-icon :icon="icon"></fa-icon>
    </div>
    <input
      v-if="upload"
      type="file"
      class="upload-hidden"
      ref="upload_file"
      :multiple="multiUpload"
      :accept="uploadAccept"
      @input="$emit('upload', $event.target.files)"
    />
  </component>
</template>
<script>
export default {
  props: {
    icon: [String, Object, Array],
    type: {
      type: String,
      default: 'primary',
      validator: type =>
        ['primary', 'secondary', 'accent', 'success', 'error', 'info', 'warning', 'default'].includes(type)
    },
    link: {
      type: String
    },
    flat: {
      type: Boolean,
      default: false
    },
    newWindows: {
      type: Boolean,
      default: false
    },
    upload: {
      type: Boolean,
      default: false
    },
    multiUpload: {
      type: Boolean,
      default: false
    },
    uploadAccept: {
      type: String
    },
    submit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tag() {
      return this.link ? 'a' : 'button'
    },
    buttonType() {
      return this.link ? null : this.submit ? 'submit' : 'button'
    },
    classes() {
      const obj = {
        btn: true
      }
      obj[this.type] = true
      obj['flat'] = this.flat
      return obj
    }
  },
  methods: {
    onClick(event) {
      if (this.upload) {
        this.$refs.upload_file.click()
      }
      this.$emit('click', event)
    }
  }
}
</script>
<style lang="scss" scoped src="@/assets/scss/components/btn.scss"></style>
