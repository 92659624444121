import Vue from 'vue'
import App from './App.vue'
import store from './utils/store'
import http from './utils/http'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false
Vue.component('fa-icon', FontAwesomeIcon)

Vue.prototype.$http = http
Vue.prototype.$store = store

new Vue({
  render: h => h(App)
}).$mount('#app')
