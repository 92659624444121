<template>
  <div class="card">
    <div v-if="image" class="card-image">
      <figure class="image">
        <img :src="image" :alt="title" />
      </figure>
    </div>
    <div class="card-content">
      <div class="card-title">{{ title }}</div>
      <div class="card-description">
        <slot></slot>
      </div>
    </div>
    <div class="card-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    image: String
  }
}
</script>
<style lang="scss" src="@/assets/scss/components/app-card.scss"></style>
