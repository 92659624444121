let API_URL = 'http://localhost:8000/v1/'
let CAS_URL = 'https://qualif.auth.agrosupdijon.fr/cas/'
let CLIENT_URL = 'http://qualif.services-apprenants.ead.agrosupdijon.fr/'

switch (process.env.NODE_ENV) {
  case 'production':
    API_URL = 'https://api.ead.agrosupdijon.fr/v1/'
    CAS_URL = 'https://auth.agrosupdijon.fr/cas/'
    CLIENT_URL = 'https://services-apprenants.ead.agrosupdijon.fr/'
    break
  case 'qualif':
    API_URL = 'https://qualif.api.ead.agrosupdijon.fr/v1/'
    break
}

export { API_URL, CAS_URL, CLIENT_URL }
