export default {
  state: {
    confirm: {
      show: false,
      type: 'danger',
      title: '',
      message: '',
      onConfirm: null
    },
    popupMessage: {
      show: false,
      type: 'error',
      message: ''
    },
    user: null
  },
  confirm(confirm) {
    this.state.confirm = {
      ...confirm,
      show: true
    }
  },
  errorConfirm(title, message, cb = null) {
    this.confirm({
      type: 'danger',
      title,
      message,
      onConfirm: cb
    })
  },
  warningConfirm(title, message, cb = null) {
    this.confirm({
      type: 'warning',
      title,
      message,
      onConfirm: cb
    })
  },
  successConfirm(title, message, cb = null) {
    this.confirm({
      type: 'success',
      title,
      message,
      onConfirm: cb
    })
  },
  infoConfirm(title, message, cb = null) {
    this.confirm({
      type: 'info',
      title,
      message,
      onConfirm: cb
    })
  },
  popup(popup) {
    this.state.popupMessage = {
      ...popup,
      show: true
    }
  },
  errorMessage(message) {
    this.popup({
      type: 'error',
      message
    })
  },
  warningMessage(message) {
    this.popup({
      type: 'warning',
      message
    })
  },
  successMessage(message) {
    this.popup({
      type: 'success',
      message
    })
  },
  infoMessage(message) {
    this.popup({
      type: 'info',
      message
    })
  },
  setUser(user) {
    this.state.user = {
      ...user
    }
  }
}
